<template>
  <div from-landing>
    <div class="dividing-line"><p>{{ $t('or') }}</p></div>
    <button class="btn-open" @click="isOpen = !isOpen">
      <span>{{ $t('signUp.alreadyMember') }}</span>
    </button>
    <div class="list-holder" v-if="isOpen">
      <p class="message" v-if="isMobile">{{ $t('_.iOSDeviceDownload') }}</p>
      <RadioButtonList v-if="typeList" :list="typeList" theme="dark" v-model="viewType" @input="updateType" />
      <div class="info-holder" :class="{'email-only': viewType !== 'Mobile'}">
        <CountryNumberDropSelect theme="single" v-if="viewTypeIsMobile" @input="updateCountry" :isFromLanding="true" />
        <ValidationProvider :rules="rules" v-slot="{errors}" class="input-field" :name="validateName">
          <TextInput v-model="personalInfo" :placeholder="placeholder" theme="dark" :digit="viewTypeIsMobile" />
          <ColorButton :type="buttonType" @click="downLoad" :disabled="(!personalInfo || !personalInfo.length) || errorMsg || Boolean(errors && errors.length)">
            <FontIcon name="down-long-solid" />
          </ColorButton>
          <ValidationErrors :errors="errors" :errorMsg="errorMsg"/>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import ColorButton from '@shared/components/common/ColorButton.vue';
import RadioButtonList from '@shared/components/common/RadioButtonList.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import CountryNumberDropSelect from '@/views/components/common/CountryNumberDropSelect.vue';
import { state } from '@shared/utils/storeUtils.mjs';
import { brandIds, siteIds } from '@/constants/base/siteMap.mjs';

export default {
  name: 'FromLanding',
  components: { CountryNumberDropSelect, TextInput, FontIcon, RadioButtonList, ColorButton },
  lexicon: 'signUp.country',
  data() {
    return {
      viewType: 'Mobile',
      typeList: null,
      personalInfo: null,
      isOpen: false,
      countryCode: null,
      language: null,
      errorMsg: null,
      TextInput,
    };
  },
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    isMobile: state('browser', 'mobile'),
    downloadUrl: state('env', 'downloadUrl'),
    viewTypeIsMobile() {
      return this.viewType === 'Mobile';
    },
    placeholder() {
      return this.$t(this.typeList.find(o => o.value === this.viewType).label);
    },
    rules() {
      if (this.viewTypeIsMobile) return 'required';
      return 'requiredSignEmail';
    },
    validateName() {
      return `_${this.viewType.toLowerCase()}`;
    },
    buttonType() {
      return this.brand === brandIds.GGPUKE ? 'gray-gold' : 'gray-red';
    }
  },
  watch: {
    personalInfo: 'initialErrorMsg'
  },
  methods: {
    initialErrorMsg() {
      this.errorMsg = null;
    },
    updateType() {
      this.personalInfo = null;
    },
    async checkUser() {
      const r = await this.$services.sign.checkUser({
        CountryCode: this.countryCode,
        SiteId: this.brand.toUpperCase(),
        LoginMethodType: this.viewType === 'Mobile' ? 0 : 2,
        Email: this.viewType === 'Mobile' ? null : this.personalInfo,
        MobileNumber: this.viewType === 'Mobile' ? this.personalInfo : null,
      });
      return r;
    },
    async downLoad() {
      const r = await this.checkUser();
      if (r.IsValid) window.open(this.downloadUrl, '_blank');
      else this.errorMsg = this.$t('INVALID_PLAYER');
    },
    updateCountry(v) {
      this.countryCode = v.CountryCode;
      this.errorMsg = null;
    },
  },
  mounted() {
    this.language = this.$services.cookie.getLanguage();
    this.typeList = [{ value: 'Mobile', label: 'mobileNumber' }, { value: 'Email', label: 'emailAddressUsername' }];
  }
};
</script>

<style lang="less">
@import '@/less/proj.less';
.GOLD {
  [from-landing] {
    .btn-open { .c(@c-gold);
      &:hover, &:focus {
        span { .-b(@c-gold); }
      }
    }
    .list-holder {
      .message { .c(@c-gold); }
      [radio-button-list] {
        & > * { flex: none; }
        [radio-item] {
          i.checked { .-c(@c-gold);
            &:after { .bgc(@c-gold); .lt(50%, 50%); .t-xy(-50%, -50%); }
            & + span { .c(@c-gold); }
          }
        }
      }
      .info-holder {
        > [country-number-drop-select] { .static(); }
        .input-field { }
      }
    }
  }
}
[from-landing] { .tc(); .c(white);
  .dividing-line { .c(#717171); .flex(); .space-between(); .items-center();
    &:before { .cnt(); .calc-w(50%, 20); .h(1); .bgc(#717171); }
    &:after { .cnt(); .calc-w(50%, 20); .h(1); .bgc(#717171); }
  }
  .btn-open { .ib(); .m(40, 10, 14); .p(10, 20); .medium(); .c(white);
    &:hover, &:focus {
      span { .-b(white); }
    }
  }
  .list-holder { .tl();
    .message {  .mb(20); }
    [radio-button-list] { .flex(); .flex-dc(); .items-start(); .justify-center();
      & > * { flex: none; }
      [radio-item] {
        i.checked {
          &:after {  .lt(50%, 50%); .t-xy(-50%, -50%); }
        }
      }
    }
    .info-holder { .mt(16);
      & > div { .mv(8); }
      > [country-number-drop-select] { .static(); }
      .input-field { .flex(); .space-between(); .items-center(); flex: 1;
        [text-input] { .rel(); .wh(calc(100% - 56px), 48); .-a(); .br(8); .bgc(rgba(200, 200, 200, 0.07)); .c(#fff); .fs(18);
          &.focus:after { .calc-w(100%, 4); .hf(); .m(); .-a(@c-yellow, 2px); .br(8); .bgc(transparent); .t-s(1); transition: none; .-box(); }
        }
        [color-button] { .flex(); .justify-center(); .items-center(); .w(48); .h(48); .ml(8);
          [font-icon] { .fs(16); .c(white); }
        }
        [validation-errors] { .abs(); .mt(90);
          &:nth-of-type(2) { .mt(120); }
        }
      }
    }
  }

  @media (@ml-up) {
    .list-holder {
      [country-number-drop-select] { .mr(8); }
      [radio-button-list] { .flex-dr(); .items-center(); }
      .info-holder { .flex(); .space-between(); .items-center();
        > * {
          &:nth-of-type(1) { .w(20%); }
          &:nth-of-type(2) { .min-w(calc(54% - 8px)); }
        }
        &.email-only {
          .input-field { .w(100%); }
        }
      }
    }
  }
  @media (@mm-down) {
    .list-holder {
      [radio-button-list] {
        &.dark {
          [radio-item]:not(:nth-of-type(1)) { .ml(0); }
        }
      }
    }
  }
}
</style>
